import logo from './assets/logos/logo.svg';
import Hamburger from 'hamburger-react'
import slackIcon from './assets/logos/slackicon.png'
import './App.scss';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Landing from './pages/Landing'
import Pricing from './pages/Pricing'
import AboutUs from './pages/AboutUs'
import Footer from './components/Footer'
import SlackLanding from './pages/SlackLanding'
import Help from './pages/Help'
import ContactUs from './components/contactUs';
import Modal from 'react-modal';
import Burger from './Burger'
import { useParams } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';

const params = new URLSearchParams(window.location.search)
const email = params.get("contactus")

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2rem',
    padding: "0",
    borderColor: '#00000033',
    borderWidth: '0.2rem',


  },
  overlay: {
    backgroundColor: 'rgb(255 255 255 / 60%)',
    zIndex: '100000000',
  }
};

function App() {

  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  const getStarted = () => {
    window.open('https://app.mixronline.com', '_blank').focus();
  }
  const home = () => {
    window.location.pathname = '/'
  }

  const openYC = () => {
    window.open('https://www.ycombinator.com/', '_blank').focus();
  }


  const [showModal, setShowModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(<></>);
  const closeModal = () => {
    setShowModal(false)
  }


  const openContactUsSales = () => {
    setModalComponent(
      <div className='contactUsModal'>
        <ContactUs email={email} type={'sales'} showClose={true} closeModal={closeModal} />
      </div>
    )
    setShowModal(true)
  }

  useEffect(() => {
    if (email) {
      openContactUsSales();
    }
  }, [])

  return (
    <div className="App">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
      >
        {modalComponent}
      </Modal>
      <Router>
        <div>
          <div className='header' >
            <div className='inner'>
              <Link className='home' to="/">
                <div className='left'>
                  <img src={logo} />
                  <h1>Mixr</h1>
                </div>
              </Link>
              <div className='right'>
                <Link className='home' activeClassName="selected" to="/">Home</Link>

                <Link
                  //  className='home' 
                  to="/pricing" activeClassName="selected">Pricing</Link>

                <Link to="/about" activeClassName="selected">About Us</Link>
                <Link activeClassName="selected" onClick={openContactUsSales}>Contact Us</Link>
                <a className='login' onClick={getStarted}>Login</a>
                <button onClick={getStarted}>
                  {/* <img src={slackIcon} /> */}
                  Get Started</button>

              </div>
              <Hamburger size={24} toggled={isOpen} toggle={setOpen} />
            </div>
          </div>
          <Burger openContactUsSales={openContactUsSales} isOpen={isOpen} closeMenu={closeMenu} getStarted={getStarted} />
          {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
          <Switch>
            <Route exact path="/">
              <Landing openContactUsSales={openContactUsSales} getStarted={getStarted} openYC={openYC} />
            </Route>
            <Route exact path="/slack">
              <SlackLanding getStarted={getStarted} openYC={openYC} home={home} />
            </Route>
            <Route path="/pricing">
              <Pricing openContactUsSales={openContactUsSales} getStarted={getStarted} />
            </Route>
            <Route path="/about">
              <AboutUs openContactUsSales={openContactUsSales} getStarted={getStarted} />
            </Route>
            <Route path="/help">
              <Help getStarted={getStarted} home={home} />
            </Route>
            <Route path="/">
              <PageNotFound />
            </Route>

          </Switch>
          <Footer openContactUsSales={openContactUsSales} getStarted={getStarted} />
        </div>
      </Router>
    </div>
  );
}

export default App;
