import React from 'react'
import logo from '../assets/logos/logo.svg'
import Fade from 'react-reveal/Fade';
export default function Footer(props) {
    return (
        <div className='footerOuter'>
            <Fade>
                <div className='footer'>
                    {/* <div className='top'> */}
                    <div className='logo'>
                        <img src={logo} />
                        <h3>Mixr</h3>
                    </div>
                    {/* <p className='contact'>
                        Contact Us<br />
                        <b>support@mixronline.com</b>
                        <br />
                    </p>*/}
                    <a onClick={props.openContactUsSales}><h3>Contact Us</h3></a>
                    {/* </div> */}
                    <a onClick={()=>{window.location.pathname='help'}}><h3>FAQ's and Help Center</h3></a>
                    <p>
                        <br />
                        © Copyright 2021 Mixronline Inc. All Rights Reserved. <br /><br /><b><a href='/documents/Terms-of-Use.pdf' target='_blank'>Terms & Conditions</a>  <a href='/documents/Privacy-Policy.pdf' target='_blank'>Privacy Policy</a></b>
                    </p>


                </div>
            </Fade>
        </div>
    )
}
