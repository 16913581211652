const qaApi = 'https://us-central1-game-tournament-45ff3.cloudfunctions.net/'
const localApi = 'http://localhost:5001/game-tournament-45ff3/us-central1/'
// const localApi = 'https://14b8-2607-fea8-2aa1-c400-4c49-b40d-7a1b-8478.ngrok.io/game-tournament-45ff3/us-central1/'
const prodApi = 'https://us-central1-game-tournament-prod-e5168.cloudfunctions.net/'

export const sendContact = async (name, email, message, type, token) => {
    let api;
    if (!!process.env.REACT_APP_IS_PROD) {
        api = prodApi
    } else if (!!process.env.REACT_APP_IS_QA) {
        api = qaApi
    } else {
        api = localApi
    }
    let requestOptions = {
        method: "POST",
        body: JSON.stringify({ name, email, message, type })
    }

    if (token) {
        requestOptions.headers = {
            Authorization: `Bearer ${token}`
        }
    }

    try {
        if (!message) return false;
        const res = await fetch(api + "contact-contact", requestOptions)

        if (res.status < 400) return true;

        return false;

    } catch (error) {
        console.log(error)
        return false;
    }
}
