import React, { useState, useEffect } from 'react'
import { sendContact } from '../services/Requests'
const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
}


export default function ContactUs(props) {

    const [sent, setSent] = useState(false)

    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companySize, setCompanySize] = useState("")
    const [stuff, setStuff] = useState(null)

    useEffect(() => {
        if (props.email && props.email !== 'blank') {
            setEmail(props.email)
        }
    }, [])

    const submitHelp = () => {
        if (!name || isBlank(name)) {
            setError('Name required');
            return
        }
        else if (!email || isBlank(email)) {
            setError('Email required');
            return
        } else if (!stuff || isBlank(stuff)) {
            setError('What can we help with?');
            return
        } else {

            let msg = `Email: ${email} \n\n Stuff:${stuff}`
            sendEmail(name, email, msg)
        }

    }

    const submitSales = () => {
        if (!name || isBlank(name)) {
            setError('Name required');
            return
        }
        else if (!email || isBlank(email)) {
            setError('Email required');
            return
        } else if (!companyName || isBlank(companyName)) {
            setError('Company Name Required');
            return
        }
        // else if (!companySize || isBlank(companySize)) {
        //     setError('Company Size Required');
        //     return
        // } 
        else {
            let msg = `Email: ${email} \n\nCompany: ${companyName} \n\nCompany Size: ${companySize}`
            sendEmail(name, email, msg)
        }

    }


    const sendEmail = (name, email, message) => {
        sendContact(name, email, message, props.type)
        setSent(true)
    }

    if (sent) {
        return (
            <div>
                <h3>
                    Thanks for reaching out!
                </h3>
                <p>We will contact you at <u>{email}</u> as soon as we can!</p>
                {props.showClose ? <button className='cancel' onClick={props.closeModal}>Close</button> : null}
            </div>
        )
    }


    if (props.type == 'help') {

        return (
            <div>
                <h3>
                    Contact Us
                </h3>
                <input name='name' type='text' value={name} placeholder='Name'
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
                <input name='email' type='email' value={email} placeholder='Email'
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }} />
                <textarea rows="4" value={stuff} placeholder='What can we help with?'
                    onChange={(e) => {
                        setStuff(e.target.value)
                    }} />
                <button onClick={submitHelp}>Send</button>
                {error ? <p className='red'>{error}</p> : null}
            </div>
        )
    }
    else if (props.type == 'sales') {

        return (
            <div>
                <h1>
                    Contact Us
                </h1>
                <p className='inputHead'>Name</p>
                <input name='name' type='text' value={name} placeholder='Name'
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
                <p className='inputHead'>Email</p>
                <input name='email' type='email' value={email} placeholder='Email'
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }} />
                <p className='inputHead'>Company Name</p>
                <input name='company' type='text' value={companyName} placeholder='Company Name'
                    onChange={(e) => {
                        setCompanyName(e.target.value)
                    }} />
                {/* <p className='inputHead'>Company Size</p>
                <input name='size' type='text' value={companySize} placeholder='Company Size'
                    onChange={(e) => {
                        setCompanySize(e.target.value)
                    }} /> */}
                <br />
                {props.showClose ? <button className='cancel' onClick={props.closeModal}>Cancel</button> : null}
                <button className='submit' onClick={submitSales}>Send</button>


                {error ? <p className='red'>{error}</p> : null}

            </div>
        )
    }
    else {
        return null
    }
}
