import React, { useState, useEffect } from 'react'
import Switch from "react-switch";
import Fade from 'react-reveal/Fade';
import { ReactComponent as Down } from '../assets/general/angle-down-solid.svg';
import { ReactComponent as Check } from '../assets/general/check-solid.svg';
import cup from '../assets/logos/mixrCup.png'
// let pricing = [
//     {
//         "name": "Squad",
//         "users": "1 - 24",
//         "cost": 99,
//     },
//     {
//         "name": "",
//         "users": "25 - 49",
//         "cost": 199,
//     },
//     {
//         "name": "Large Team",
//         "users": "50 - 99",
//         "cost": 399,
//     },
// ]
let pricing = [
    {
        "name": "Squad",
        "users": "1 - 24",
        "cost": 40,
    },
    {
        "name": "",
        "users": "25 - 49",
        "cost": 80,
    },
    {
        "name": "Large Team",
        "users": "50 - 99",
        "cost": 160,
    },
]
// let pricingM = [
//     {
//         "name": "Squad",
//         "users": "1 - 24",
//         "cost": 119,
//     },
//     {
//         "name": "",
//         "users": "25 - 49",
//         "cost": 239,
//     },
//     {
//         "name": "Large Team",
//         "users": "50 - 99",
//         "cost": 479,
//     },
// ]
let pricingM = [
    {
        "name": "Squad",
        "users": "1 - 24",
        "cost": 49,
    },
    {
        "name": "",
        "users": "25 - 49",
        "cost": 99,
    },
    {
        "name": "Large Team",
        "users": "50 - 99",
        "cost": 199,
    },
]

let features = [

    {
        "f": 'Slack integration or corporate email login'
    },
    {
        "f": 'Mixr game tournaments for up to 50 people'
    },
    {
        "f": 'Automatic breakout rooms'
    },
    {
        "f": 'Unlimited tournaments'
    },
    {
        "f": "Mixr Office Champion leaderboard"
    },
    {
        "f": "Game GOAT Leaderboards"
    },
    {
        "f": 'Video/audio chat'
    },
    {
        "f": "Ongoing seasons and trophies"
    },
    {
        "f": 'Access to all current and future games'
    },


]

export default function Pricing(props) {

    const [checked, setChecked] = useState(1);

    const handleChange = (checked) => {
        setChecked(checked);
    }

    var p = [];
    if (checked) {
        p = pricing
    } else {
        p = pricingM
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='pricing'>
            {/* <div className='title'> */}
            {/* <h1>🏆</h1> */}
            {/* <h1>The Ultimate Office Competition</h1> */}

            {/* <h1>🏆</h1> */}
            {/* </div> */}
            <div className='topP'>


                <div>
                    <div className='card'>
                        <img className='cup' src={cup} />
                        <Fade>
                            <h3>The Ultimate <br /> Office Competition</h3>
                        </Fade>
                        <Fade>
                            <p>Starts at</p>
                            <span className='price'>$40<span>/mo</span></span>
                        </Fade>
                        <img className='cup2' src={cup} />


                        <button onClick={props.getStarted}>Start Free Trial</button>

                        <Fade>
                            <p>Free 14 day trial.</p>
                        </Fade>

                    </div>

                    <Fade>
                        <h1>Building better teams and stronger remote culture. </h1>

                    </Fade>
                    <Fade  >
                        <p>
                            Pricing Tiers
                        </p>
                        <Down />
                    </Fade>
                </div>
            </div>

            <div className='pricingT'>

                <Fade>
                    <h3>Pricing Tiers</h3>
                </Fade>

                <Fade>
                    <div className='plans'>
                        <div className='billTypeSelector'>
                            <span className='left'>
                                Billed Monthly<span></span>
                            </span>
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                onColor="#8e7eff"
                                onHandleColor="#efefef"
                                handleDiameter={18}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={22}
                                width={36}
                                className="react-switch"
                                id="material-switch"
                            />
                            <span className='right'>
                                Billed Annually
                            </span>
                        </div>

                        {p.map((i) => {
                            return (
                                <div className='container'>
                                    <div>
                                        {/* <p className='name'>{i.name}</p> */}
                                        <p className='size'>{i.users}</p>
                                        <p className='users'>Team Size</p>
                                        <p className='price'>{`$${i.cost}`}<span>{"/mo"}</span></p>
                                        <p className='billed'>Billed{checked ? ' Annually' : ' Monthly'} </p>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='container'>
                            <div className='oneplpus' onClick={props.openContactUsSales}>
                                {/* <p className='name'>Enterprise</p> */}
                                <p className='size'>100+</p>
                                <p className='users'>Team Size</p>
                                <p className='priceC'>Contact<br /> Us</p>
                                <p className='billedC'>Billed{checked ? ' Annually' : ' Monthly'}</p>
                            </div>
                        </div>

                    </div>

                </Fade>
                
                <button className='underButton' onClick={props.getStarted}>Start Free Trial</button>
                <Fade>
                    <p >Free 14 day trial.</p>
                </Fade>
                <h2 className='or'>or</h2>
                <button onClick={props.openContactUsSales}>Contact Us</button>

            </div>
            <div className='features'>
                <Fade>
                    <h1>Features</h1>
                </Fade>
                <div className='list'>
                    {features.map((f, i) => {
                        return (
                            <Fade>
                                <div className={`feature num${i % 2}`}>
                                    <span className='des' > {f.f}</span>
                                    <span className='emoji'><Check/></span>

                                </div>
                            </Fade>
                        )
                    })}
                </div>
            </div >

        </div >
    )
}
