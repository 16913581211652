import React from 'react'
import slackIcon from './assets/logos/slackicon.png'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default function Burger(props) {
    return (
        <div className={`burgerMenu open${props.isOpen}`}>
            <Link onClick={props.closeMenu} className='home' to="/">Home</Link>
            <br />
            <Link onClick={props.closeMenu} to="/pricing">Pricing</Link>
            <br />
            <Link onClick={props.closeMenu} to="/about">About Us</Link>
            <br/>
            <Link onClick={props.openContactUsSales} >Contact Us</Link>
            <br />
            <a onClick={() => {
                props.closeMenu();
                props.getStarted();
            }} className='login'>Login</a>
            <br />
            <button onClick={() => {
                props.closeMenu();
                props.getStarted();
            }} >Get Started</button>
        </div >
    )
}
