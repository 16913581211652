import React from 'react'
import ContactUs from '../components/contactUs'

const params = new URLSearchParams(window.location.search)
const email = params.get("email") 

const faqs = [
    {
        question: 'What is Mixr?',
        answer: "Mixr is a social teambuilding platform where co-workers connect and complete in online game tournaments.  Game tournaments have fully integrated audio/video, automated breakout rooms and embedded games.  Collect trophies by playing tournaments to climb the Office Leaderboard."
    },
    {
        question: 'How many people can be in Mixr?',
        answer: 'Mixr is an Office Competition where unlimited people can take part.  In an individual game tournament, there can be up to 50 people playing.'
    },
    {
        question: 'What if I disconnect from the video chat?',
        answer: "Don't worry! We save all your progress, just refresh."
    },
    {
        question: 'Can Mixr be used without Slack?',
        answer: 'Yes!  You can sign up with email... Microsoft Teams support coming soon!'
    },
]

export default function Help(props) {
    return (
        <div className='help'>

            <div className='topH'>
                <h1>
                    Need Help?
                </h1>
                <div className='topC'>
                    <ContactUs type='help' email={email} />
                </div>
            </div>

            <div className='faqs'>
                <h1>
                    FAQ's
                </h1>
                {faqs.map(faq => {
                    return (
                        <div className='faq'>
                            <h3>{faq.question}</h3>
                            <p>{faq.answer}</p>
                        </div>
                    )
                })}



            </div>

            <div className='learnmore'>
                <h1>Interested in learning more about Mixr?</h1>
                <button onClick={props.home}>Learn more</button>
            </div>

        </div>
    )
}
