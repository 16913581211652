import React, { useEffect, useState } from 'react'
import podium from '../assets/landing/podium.png'
import lobby from '../assets/landing/lobby.png'
import gameRoom from '../assets/landing/gameRoom.png'
import inGame from '../assets/landing/inGame.png'
import leaderboard from '../assets/landing/leaderboard.png'
import officeChampion from '../assets/landing/officeChampion.png'
import Fade from 'react-reveal/Fade';
import { ReactComponent as Lock } from '../assets/general/lock-solid.svg';
import { ReactComponent as Shield } from '../assets/general/shield-alt-solid.svg';
import { ReactComponent as Down } from '../assets/general/angle-down-solid.svg';
import cup from '../assets/logos/PodiumPurple.png'
import YC from '../assets/logos/ycLogo.png'
import SlackIcon from '../assets/logos/slackicon.png'
import ReactPlayer from 'react-player'

import image1 from '../assets/landing/images1.png'
import image2 from '../assets/landing/images2.png'

export default function Landing(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [gNum, setGNum] = useState(0);

    const iGNum = () => {
        setGNum(gNum + 1)
    }
    const dGNum = () => {
        setGNum(gNum - 1)
    }



    let games = [
        {
            s: '1.5rem',
            "asset": "https://images.mixronline.com/images/2021/07/21/assets.png",
            "background": "https://images.mixronline.com/images/2021/04/05/gamebackground.png",
            "gameId": "00000",
            "instructions": {
                "goal": "Create full rows of blocks to gain points.",
                "how": "Use the LEFT and RIGHT arrow keys to move the block.  UP arrow key to rotate. DOWN arrow key to speed up the block.",
                "tips": "Filling and clearing multiple rows with a single block doubles the points."
            },
            "logo": "https://images.mixronline.com/images/2022/01/20/Untitled-drawing-4.png",
            "maxPlayers": 5, "maxTime": 0.35, "name": "Jet Jump",
            "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/21/tetrisPhotobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[83,80,373,210]]}\"",
            "tutorial": "",
            "url": "https://tetris-ajp.pages.dev/"
        },
        {
            s: '1.5rem',
            "asset": "https://images.mixronline.com/images/2021/07/21/assets.png",
            "background": "https://images.mixronline.com/images/2021/07/21/Background.png",
            "gameId": 11111,
            "instructions": {
                "goal": "Create full rows of blocks to gain points.",
                "how": "Use the LEFT and RIGHT arrow keys to move the block.  UP arrow key to rotate. DOWN arrow key to speed up the block.",
                "tips": "Filling and clearing multiple rows with a single block doubles the points."
            },
            "logo": "https://images.mixronline.com/images/2021/07/26/blockrain-logo-3.png",
            "maxPlayers": 5, "maxTime": 0.35, "name": "BlockRain",
            "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/21/tetrisPhotobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[83,80,373,210]]}\"",
            "tutorial": "",
            "url": "https://tetris-ajp.pages.dev/"
        },
        {
            s: '1.9rem',
            "asset": "https://images.mixronline.com/images/2021/07/21/2048tile.png",
            "background": "https://images.mixronline.com/images/2021/07/21/Background.jpg",
            "gameId": 22222,
            "instructions": { "goal": "Gain points by combining number tiles to create bigger numbers!", "how": "Use the arrow keys to move the tiles.", "tips": "You can only combine tiles with the same numbers." }, "logo": "https://images.mixronline.com/images/2021/07/21/2048_logo-2.png", "maxPlayers": 5, "maxTime": 0.35, "name": "2048", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/21/2048Photobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[111,77,324,182]]}\"", "tutorial": "", "url": "https://2048-bio.pages.dev/"
        }, {
            s: '1.5rem',
            "asset": "https://images.mixronline.com/images/2021/07/21/ball1.png",
            "background": "https://images.mixronline.com/images/2021/07/29/bg2-copy.png",
            "gameId": 33333,
            "instructions": {
                "goal": "Score as many baskets as you can.",
                "how": "Click and drag to move the net and catch the basket balls.",
                "tips": "Scoring multiple swishes in a row results in extra points!"
            },
            "logo": "https://images.mixronline.com/images/2021/07/21/logo.png",
            "maxPlayers": 5, "maxTime": 0.2, "name": "Hoop Star", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/21/hoopstarPhotobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[141,189,324,182]]}\"", "tutorial": "", "url": "https://hoop-star.pages.dev/"
        }, {
            s: '1.5rem',
            "asset": "https://images.mixronline.com/images/2021/07/21/asseta76d38962913f54a.png",
            "background": "https://images.mixronline.com/images/2021/07/21/Background-2a7fe308ad6ee3aa9.jpg",
            "gameId": 44444, "instructions": {
                "goal": "Create the highest stack!", "how": "Click to place the stack tile.",
                "tips": "Perfect stacks result in more points."
            },
            "logo": "https://images.mixronline.com/images/2021/07/21/logo7332738d520e5379.png", "maxPlayers": 5, "maxTime": 0.25,
            "name": "Rainbow Stacker",
            "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/22/rainbowStackPhotobooth-4.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[82,44,402,226]]}\"", "tutorial": "", "url": "https://rainbow-stack.pages.dev/"
        },
        { s: '1.5rem', "asset": "https://images.mixronline.com/images/2021/07/21/planet8f21ce31ced3f3df.png", "background": "https://images.mixronline.com/images/2021/07/21/Backgroundc067eb4314c86f16.jpg", "gameId": 55555, "instructions": { "goal": "Collect coins and get to the yellow area!  Watch out for the obstacles!", "how": "Click and drag to move the green square around the map.", "tips": "Complete levels to gains points. Collecting all the coins, as fast as possible and losing the least amount of lives results in more points." }, "logo": "https://images.mixronline.com/images/2021/07/21/hgoeLogo6e933e3e4f6c3381.png", "maxPlayers": 5, "maxTime": 0.35, "name": "Hardest Game On Earth", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/22/hgoePhotobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[92,71,325,183]]}\"", "tutorial": "", "url": "https://hgoe.pages.dev/" },
        { s: '1.5rem', "asset": "https://images.mixronline.com/images/2021/07/21/yellowe8c6cd6a3d0994fe.png", "background": "https://images.mixronline.com/images/2021/10/08/Untitled-drawing---2021-10-08T123754.739.png", "gameId": 66666, "instructions": { "goal": "Match 3 or more to gain points!", "how": "Click and drag to switch adjacent candy. ", "tips": "Bombs are helpful! Use them to clear lots of candy at once" }, "logo": "https://images.mixronline.com/images/2021/07/21/item-titled56e0573b4aaabc1.png", "maxPlayers": 5, "maxTime": 0.35, "name": "Candy Jam", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/28/candyJamPhotobooth-1.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[13,56,507,285]]}\"", "tutorial": "", "url": "https://candy-jam.pages.dev/" },
        { s: '1.5rem', "asset": "https://images.mixronline.com/images/2021/07/21/fruitblade-assetf128ab6f9fdd0f8a.png", "background": "https://images.mixronline.com/images/2021/07/29/bg.png", "gameId": 77777, "instructions": { "goal": "Collect points by slicing the fruit.  Don't hit the bombs!", "how": "Click and drag to move your sword and slice fruit. ", "tips": "Slicing 3 or more fruit results in a combo earning you extra points!" }, "logo": "https://images.mixronline.com/images/2021/07/21/maintitle8524fad6fc6dc14a.png", "maxPlayers": 5, "maxTime": 0.3, "name": "Fruit Blade", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/07/21/fruitBladePhotobooth.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[57,177,423,238]]}\"", "tutorial": "", "url": "https://fruit-blade.pages.dev/" },
        { s: '1.5rem', "asset": "https://images.mixronline.com/images/2021/10/20/flappyAsset.png", "background": "https://images.mixronline.com/images/2021/10/20/flappyBack.jpg", "gameId": 88888, "instructions": { "goal": "Fly through as many pipes as you can!", "how": "Click to flap your wings.", "tips": "Focusing only on the bottom pipe and jumping close to it makes it easier!" }, "logo": "https://images.mixronline.com/images/2021/10/20/flappy-Logo.png", "maxPlayers": "5", "maxTime": 0.22000000000000003, "name": "Floppy Bird", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/10/20/flappyPhotoboothAhhh.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[47,100,444,250]]}\"", "tutorial": "", "url": "https://flappybird-426.pages.dev/" },
        {
            s: '1.5rem',
            "asset": "https://images.mixronline.com/images/2021/10/20/pacmanAsset.png", "background": "https://images.mixronline.com/images/2021/10/20/pacmonBack.jpg", "gameId": 99999, "instructions": { "goal": "Collect all of the dots, avoid the ghosts!", "how": "Use ARROW KEYS to move around the map.", "tips": "Eating the bigger dots turns ghosts purple.  You can then eat them for more points!" }, "logo": "https://images.mixronline.com/images/2021/10/20/pacman-Logo.png", "maxPlayers": "5", "maxTime": 0.3, "name": "Ghost Run", "photobooth": "\"{\\\"url\\\":\\\"https://images.mixronline.com/images/2021/10/20/pacmanPhotobooth-2.png\\\",\\\"caption\\\":\\\"\\\",\\\"facePos\\\":[[15,47,507,285]]}\"", "tutorial": ""
            , "url": "https://pacman-5yk.pages.dev/"
        }]



    const openForm = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScrfBnVdIyQX0eTcRd2D67N8COmYdzsarAWoFReKWr8s2uaMg/viewform", "_blank")

    }

    let g = games[Math.abs(gNum % games.length)];

    return (
        <div className='landing'>
            <div className='top'>
                <div className='mid'>

                    <Fade  >
                        <img src={cup} />

                        {/* <h1>The Ultimate Office Competition</h1> */}

                        <h1>Welcome to Mixr</h1>
                        <h2>Building better teams and stronger remote culture.</h2>
                        <Fade  >
                            <div 
                            className = 'video'
                            >
                                <ReactPlayer
                                    url='https://mixronline-website.us-southeast-1.linodeobjects.com/video.mp4'
                                    controls={true}
                                    volume={0.5}
                          
                                    
                                />
                            </div>


                        </Fade>
                        
                    </Fade>


                    {/* <Fade  > */}
                    <button onClick={props.getStarted} >
                        {/* <img src={SlackIcon}/> */}
                        <h3>Get Started For Free</h3></button>
                    {/* <Fade  >
                        <h3 className='contactUs' onClick={props.openContactUsSales}>Contact us</h3>
                    </Fade> */}
                    {/* <button className='slackLanding' onClick={props.getStarted} >
                        <img src={SlackIcon}/>
                    <h3>Add Mixr to Slack</h3></button> */}
                    {/* </Fade> */}
                    <Fade  >
                        <p className='yc'><span>Backed by</span> <img onClick={props.openYC} src={YC} /></p>



                        <p>
                            Learn more
                        </p>
                        <Down />
                    </Fade>
                </div>

            </div>



            {/* <div className='games' 
            // style={{ backgroundImage: `url(${g.background})` }}
            >
                <Fade  >
                    <h2>Mixr Game Library</h2>
                </Fade>
                <div className='buttons'>
                    <button onClick={dGNum}>Prev</button>
                    <button onClick={iGNum}>Next</button>
                </div>

                <img className='gLogo' src={g.logo} />
                <h2>{g.name}</h2>
                <iframe className='gIframe' src={g.url + "?audio=0"} />



                <div className='gamesContainer'>
                    <div className='banner'>
                        {games.map(g => {
                            return (
                                <Fade  >
                                    <div className='gameContainer'>
                                        <div className={`game ${g.n}`} style={{ backgroundImage: `url(${g.b})` }} >
                                            <img src={g.l} />
                                            <span style={{ fontSize: g.s }}>
                                                {g.n}
                                            </span>
                                        </div>
                                    </div>
                                </Fade>
                            )
                        })}
                    </div>
                </div>
                <Fade  >
                    <p>New games added every season!</p>
                </Fade> 
            </div> */}


            <div className='images'>

                <Fade  >
                    {/* <h2>Running engaging virtual events is difficult.</h2>
                    <br />
                    <br />
                    <h2>Thats why we built Mixr!</h2>
                    <br />
                    <br /> */}
                    <h2>Engaging team-building through online game tournaments.</h2>
                    <img className='podium addB'
                         src={"https://images.mixronline.com/images/2022/01/18/images1.jpg"}
                        // src={image1}
                    />
                </Fade>
                <Fade  >
                    <h2>Connect and socialize while playing arcade games.</h2>
                    <img className='podium addB'
                        src={"https://images.mixronline.com/images/2022/01/18/images2.jpg"} 
                        // src={image2}
                    />
                </Fade>

                {/* <Fade  >
                    <h2>Earn your spot on the podium.</h2>
                    <img className='podium' src={podium} />
                </Fade> */}
                <Fade  >
                    <h2>Play tournaments.<br /> Collect trophies.<br /> Compete to become the Company Champion!</h2>
                    <img className='podium addB championImg' 
                    src={"https://images.mixronline.com/images/2022/01/18/podiumWelcome-1.jpg"} 
                    />
                </Fade>
            </div>
            <div className='featuresOuter'>
                {/* <h2>Features</h2> */}
                <div className='features'>
                    <Fade  >
                        <div className='featureContainer'>
                            <img src={"https://images.mixronline.com/images/2021/08/05/features1.png"} />
                            <div className='content'>
                                <h2>Easy to set up,<br /> easy to play.</h2>
                                <p>

                                    {/* ➡️ Sign up. <br /> ➡️ Click "Add to Slack".
                                    <br /> ➡️ Go to Slack shortcuts.
                                    <br /> ➡️ Click "Start Mixr". */}
                                    {/* <br /><br />
                                    From here you can start or schedule a Mixr! */}
                                    {/* <br /> */}
                                    {/* Leaderboards to check out 🏆 The Office Champions and 🐐 Game GOAT's (Greatest of All Teams). */}


                                    {/* One click to add it to your Slack, everything else is handled by us!
                                    <br />
                                    <br />
                                     */}
                                    {/* <br />
                                    <br />
                                    Anyone can start tournaments, and they run autonomously, <b>no admin needed!</b> */}
                                    Sign up with email or add Mixr to your Slack workspace and host a game tournament in less than 5 minutes.
                                    <br />
                                    <br />
                                    Tournaments can be started by anyone and are managed with 1 click.
                                    <br />
                                    <br />

                                    <button onClick={props.getStarted} >
                                        {/* <img src={SlackIcon}/> */}
                                        <h3>Get Started</h3></button>
                                    {/* Not using Slack?  No worries, we have full web support as well. */}
                                    {/* {"Microsoft Teams and Google Chat support coming soon! "} */}
                                    {/* <br/> */}
                                    {/* <br /> */}
                                    {/* <a onClick={openForm}>Sign up for the waitlist.</a> */}
                                    {/* <button><img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg/1200px-Microsoft_Office_Teams_%282018%E2%80%93present%29.svg.png'} />
                                        Sign up for waitlist</button> */}
                                </p>
                                {/* <ul>
                                <li>One click to add it to your Slack, everything else is handled by us!</li>
                                <li>Anyone can start tournaments, no admins needed, all within your slack workspace!</li>
                            </ul> */}
                            </div>

                        </div>
                    </Fade>
                    <Fade  >
                        <div className='featureContainer'>
                            <img src={"https://images.mixronline.com/images/2022/01/18/features2-1.jpg"} />
                            <div className='content'>
                                <h2>A remote manager's dream.</h2>
                                <p>

                                    Mixr handles every aspect of the tournament, allowing you and your employees to focus on what's important...
                                    <b> connecting, socializing and having fun!</b>
                                    <br />

                                    <br />

                                    What we do:

                                    <br />
                                    ✅ Video/audio chat.
                                    <br />
                                    ✅ Automated breakout rooms.
                                    <br />
                                    ✅ Integrated games.
                                    <br />
                                    ✅ One click tournaments.
                                    <br />
                                    ✅ Organization wide leaderboards.


                                </p>
                                {/* <ul>
                                <li>One click to add it to your Slack, everything else is handled by us!</li>
                                <li>Anyone can start tournaments, no admins needed, all within your slack workspace!</li>
                            </ul> */}
                            </div>

                        </div>
                    </Fade>
                    <Fade  >
                        <div className='featureContainer'>
                            <img src={"https://images.mixronline.com/images/2021/08/05/features3.png"} />
                            <div className='content'>
                                <h2>Built for ongoing engagment.</h2>
                                <p>
                                    When a Mixr tournament ends, the competition begins.
                                    <br /><br />

                                    Play tournaments, get highscores and collect trophies to become an
                                    Office Champion 🏆
                                    or

                                    Game GOAT 🐐 (Greatest of All Teams).
                                    <br />
                                    <br />

                                    <b>🕹️ Mixr is the new remote Rec Room!</b>
                                </p>
                                {/* <ul>
                                <li>One click to add it to your Slack, everything else is handled by us!</li>
                                <li>Anyone can start tournaments, no admins needed, all within your slack workspace!</li>
                            </ul> */}
                            </div>

                        </div>
                    </Fade>
                    <Fade  >
                        <div className='featureContainer'>
                            <img src={"https://images.mixronline.com/images/2022/01/18/features4.jpg"} />
                            <div className='content'>
                                <h2>Games that are actually fun.</h2>
                                <p>
                                    We have had enough with boring trivia.
                                    <br />
                                    <br />
                                    Mixr games are simple and easy to play, but still competitive and fun.
                                    <br /><br />
                                    Games are integrated within fast paced rounds in automated breakout rooms.
                                    <br /><br />

                                    {/* <br /><br /> */}
                                    <b>⭐ Our games are based on all-time favorites! </b>

                                </p>
                                {/* <ul>
                                <li>One click to add it to your Slack, everything else is handled by us!</li>
                                <li>Anyone can start tournaments, no admins needed, all within your slack workspace!</li>
                            </ul> */}
                            </div>

                        </div>
                    </Fade>
                </div>

            </div>



            <div className='games'>
                <Fade  >
                    <h2>Mixr Game Library</h2>
                </Fade>
                <div className='gamesContainer'>
                    <div className='banner'>
                        {games.map(g => {
                            return (
                                <Fade  >
                                    <div className='gameContainer'>
                                        <div className={`game ${g.name}`} style={{ backgroundImage: `url(${g.background})` }} >
                                            <img src={g.logo} />
                                            <span style={{ fontSize: g.s }}>
                                                {g.name}
                                            </span>
                                        </div>
                                    </div>
                                </Fade>
                            )
                        })}
                    </div>
                </div>
                <Fade  >
                    <p>New games added every season!</p>
                </Fade>
            </div>




            {/* <div className='connections'>
                <Fade>
                    <h2>Team socials are critical to team success.</h2>
                </Fade>

                <Fade>
                    <div className='content'>
                        <h3>Social connections at work <b>increase happiness, engagement and loyalty</b>, while decreasing stress. </h3>
                        <a target='_blank' href='https://www.forbes.com/sites/alankohll/2018/01/31/5-ways-social-connections-can-enhance-your-employee-wellness-program/?sh=19d83ecd527c'>Read More</a>
                        <br /><br />
                        <h3>Team building is <b>the most important investment for your people.</b> </h3>
                        <a target='_blank' href='https://www.forbes.com/sites/brianscudamore/2016/03/09/why-team-building-is-the-most-important-investment-youll-make/?sh=2c39bfcf617f'>Read More</a>

                    </div>
                </Fade>


            </div> */}



            {/* <div className='feedback'>
                <Fade>
                    <div className='container'>
                        <p className='quote'>"Mixr is the coolest thing we have ever tried, I would suggest you sign up, definetly worth the cost!"</p>
                        <div className='person'>
                            <img src={'https://media-exp1.licdn.com/dms/image/C4D03AQHiaH3QdwfVkQ/profile-displayphoto-shrink_800_800/0/1624938199960?e=1632960000&v=beta&t=zTWBIvuF2bfYbRXbWyMbd4h7j2gqo0es2wqqZpb2jy4'} />
                            <div>
                                <h3>Blaze Cerovcec</h3>
                                <p>Co-Founder of Mixr</p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className='container'>
                        <p className='quote'>"Mixr is the coolest thing we have ever tried, I would suggest you sign up, definetly worth the cost!"</p>
                        <div className='person'>
                            <img src={'https://media-exp1.licdn.com/dms/image/C4D03AQHiaH3QdwfVkQ/profile-displayphoto-shrink_800_800/0/1624938199960?e=1632960000&v=beta&t=zTWBIvuF2bfYbRXbWyMbd4h7j2gqo0es2wqqZpb2jy4'} />
                            <div>
                                <h3>Blaze Cerovcec</h3>
                                <p>Co-Founder of Mixr</p>
                            </div>
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className='container'>
                        <p className='quote'>"Mixr is the coolest thing we have ever tried, I would suggest you sign up, definetly worth the cost!"</p>
                        <div className='person'>
                            <img src={'https://media-exp1.licdn.com/dms/image/C4D03AQHiaH3QdwfVkQ/profile-displayphoto-shrink_800_800/0/1624938199960?e=1632960000&v=beta&t=zTWBIvuF2bfYbRXbWyMbd4h7j2gqo0es2wqqZpb2jy4'} />
                            <div>
                                <h3>Blaze Cerovcec</h3>
                                <p>Co-Founder of Mixr</p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div> */}




            <div className='security'>
                <Fade>
                    <div>
                        <h2>Enterprise Level Security</h2>

                        <p>Keeping your data private is our top priority, that's why we use the best tech in the business.</p>
                    </div>

                    <div className="item">
                        <div className='title'>
                            <div>
                                <Lock />
                                <h2>Single Sign On</h2>
                            </div>
                        </div>
                        <div>
                            <img src='https://techcrunch.com/wp-content/uploads/2020/07/auth0-logo-whitebg.png' />
                            <p>Secure sign in and user protection. <br /> <a target='_blank' href='https://auth0.com/login-security'>Learn More</a></p>
                        </div>
                    </div>
                    <div className="item">
                        <div className='title'>
                            <div>
                                <Shield />
                                <h2>Encrypted Video</h2>
                            </div>
                        </div>
                        <div>
                            <img src='https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/tu6aiehgrvca2af67nqt' />
                            <p>Fully encrypted and secure video chat.  <br /><a target='_blank' href='https://www.daily.co/security'>Learn More</a></p>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className='connections'>
                <Fade>
                    <h2>Team socials are critical to team success.</h2>
                </Fade>

                <Fade>
                    <div className='content'>
                        <h3>Social connections at work <b>increase happiness, engagement and loyalty</b>, while decreasing stress. </h3>
                        <a target='_blank' href='https://www.forbes.com/sites/alankohll/2018/01/31/5-ways-social-connections-can-enhance-your-employee-wellness-program/?sh=19d83ecd527c'>Read More</a>
                        <br /><br />
                        <h3>Why team building is <b>the most important investment for your people.</b> </h3>
                        <a target='_blank' href='https://www.forbes.com/sites/brianscudamore/2016/03/09/why-team-building-is-the-most-important-investment-youll-make/?sh=2c39bfcf617f'>Read More</a>

                    </div>
                </Fade>


            </div>

            <div className='freeTrial'>
                <Fade>
                    <h2>Set up your free trial in less than 5 minutes.</h2>


                </Fade>
                <button onClick={props.getStarted}>Start Free Trial</button>
            </div>



        </div >
    )
}
