import React, { useEffect } from 'react'
import teamwork from '../assets/aboutus/teamwork.png'
import { ReactComponent as Arrow } from '../assets/general/long-arrow-alt-right-solid.svg'
import logo from '../assets/logos/logo.svg';
import oldLogo from '../assets/logos/oldLogo.png'
import Fade from 'react-reveal/Fade';
export default function AboutUs(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='aboutus'>
            <Fade>
                <div className='mission'>

                    <h1>
                        Our Mission
                    </h1>
                    <img src={teamwork} />
                    <h3>
                        To enable companies to build better teams and a stronger remote culture.
                    </h3>

                </div>
            </Fade>

            <div className='founders'>
                <Fade>
                    <h1>
                        The Founders
                    </h1>
                </Fade>
                <Fade>
                    <div className='founder'>
                        <img src='https://images.mixronline.com/images/2021/10/13/Screen-Shot-2021-10-13-at-10.11.37-AM.png' />
                        <h3>Blaze Cerovcec</h3>
                        
                        <p>Software engineer and Fruit Blade wizard.</p>
                    </div>
                </Fade>
                <Fade>
                    <div className='founder'>
                        <img src='https://images.mixronline.com/images/2021/09/20/garvan.png' />
                        <h3>Garvan Doyle</h3>
                        <p>Software engineer and Rainbow Stacking legend.</p>
                    </div>
                </Fade>
            </div>
            <Fade>
                <div className='story'>
                    <h1>
                        Our Story
                    </h1>

                    <p>
                        Mixr started as a place to party online.{/* <br /><br /> */} When the pandemic hit, we were in our last
                        year of university.  We wanted to get together with friends online, but there were no fun video chats for large groups of people.
                        <br />
                        <br />
                        We immediatly got to work on Mixr, the online party
                        platform where any number of people could join a house
                        and be automatically mixed into random breakout rooms. Mixr took
                        off. We hosted over a million online parties and
                        numerous events for Canadian universities throughout that year.

                        <br />
                        <br />
                        As school came to an end, we joined the workforce as remote developers, running Mixr on the side.
                        We experienced 'remote work culture'.  A dreaded monthly social, playing trivia on a zoom call.
                        Eveyone with their camera and mics turned off.
                        It was un-engaging, it felt awkward, and what stuck out the most... we didn't connect with a single person.

                        <br /><br />
                        This left us feeling
                        isolated, unmotivated and disconnected from our teams.

                        <br /><br />
                        Running engaging virtual socials is difficult.  We of all people know this, we had focused on
                        it the entire time with Mixr!

                        We are excited to bring everything we've learned
                        hosting virtual parties to the remote workplace, so
                        remote employees
                        never feel isolated again.

                        {/* We know we can make the remote expirience better and built Mixr as it is today
                        so remote leaders would never have to worry about their remote employees feeling isolated from the rest of the team.
                        <br />
                        <br /> */}
                        {/* This is what makes us different... Mixr was specifically designed 
                        for fun and socializing first, and
                        corporate second.
                        <br />
                        <br /> */}
                        {/* We are now excited to bring everything we've learned 
                        hosting virtual parties to the remote workplace. */}



                    </p>
                    <div className='logos'>
                        <img src={oldLogo} />
                        <Arrow />
                        <img src={logo} />
                    </div>
                </div>
            </Fade>
            <div className='tryNow'>
                <Fade>
                    <h1>
                        Start building great culture for your remote/hybrid teams.
                    </h1>
                </Fade>
                <button onClick={props.getStarted}>Start Free Trial</button>
                <Fade>
                    <p>14 day free trial</p>
                </Fade>
            </div>
        </div>
    )
}
