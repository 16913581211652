import React, { useEffect } from 'react'
import podium from '../assets/landing/podium.png'
import lobby from '../assets/landing/lobby.png'
import gameRoom from '../assets/landing/gameRoom.png'
import inGame from '../assets/landing/inGame.png'
import leaderboard from '../assets/landing/leaderboard.png'
import officeChampion from '../assets/landing/officeChampion.png'
import Fade from 'react-reveal/Fade';
import { ReactComponent as Lock } from '../assets/general/lock-solid.svg';
import { ReactComponent as Shield } from '../assets/general/shield-alt-solid.svg';
import { ReactComponent as Down } from '../assets/general/angle-down-solid.svg';
import cup from '../assets/logos/PodiumPurple.png'
import YC from '../assets/logos/ycLogo.png'
import SlackIcon from '../assets/logos/slackicon.png'
import loginP from '../assets/slackLanding/login.jpg'
import addP from '../assets/slackLanding/add.jpg'
import planP from '../assets/slackLanding/plan.jpg'
import slackP from '../assets/slackLanding/slack.jpg'
import logo from '../assets/logos/logo.svg'
import {
    Link
} from "react-router-dom";

export default function SlackLanding(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let games = [
        {
            b: 'https://images.mixronline.com/images/2021/07/29/bg.png',
            l: 'https://images.mixronline.com/images/2021/07/21/maintitle8524fad6fc6dc14a.png',
            n: 'Fruit Blade',
            s: '2rem',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/29/background-2.png',
            l: 'https://images.mixronline.com/images/2021/07/21/item-titled56e0573b4aaabc1.png',
            n: 'Candy Jam',
            s: '2rem',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/29/bg2-copy.png',
            l: 'https://images.mixronline.com/images/2021/07/21/logo.png',
            n: 'Hoop Star',
            s: '2rem',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/21/Backgroundc067eb4314c86f16.jpg',
            l: 'https://images.mixronline.com/images/2021/07/21/hgoeLogo6e933e3e4f6c3381.png',
            n: 'Hardest Game on Earth',
            s: '1.6rem',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/21/Background.png',
            l: 'https://images.mixronline.com/images/2021/07/26/blockrain-logo-3.png',
            n: 'Blockrain',
            s: '2em',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/21/Background.jpg',
            l: 'https://images.mixronline.com/images/2021/07/21/2048_logo-2.png',
            n: '2048',
            s: '2.5em',
        },
        {
            b: 'https://images.mixronline.com/images/2021/07/26/Background-2a7fe308ad6ee3aa9.jpg',
            l: 'https://images.mixronline.com/images/2021/07/21/logo7332738d520e5379.png',
            n: 'Rainbow Stacker',
            s: '1.8em',
        }
    ]

    const openForm = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScrfBnVdIyQX0eTcRd2D67N8COmYdzsarAWoFReKWr8s2uaMg/viewform", "_blank")

    }

    return (
        <div className='slackLanding'>
            <div className='topS'>
                <div className='topLogo'>
                    <div>
                        <img src={logo} />
                        <h1>Mixr</h1>
                    </div>

                    <h1>|</h1>

                    <div>
                        <img src={SlackIcon} />
                        <h1>Slack</h1>
                    </div>
                </div>
                <h1>Build better teams and stronger remote culture inside Slack</h1>
                <p>Bring remote employees together with fun and engaging game tournaments!
                </p>
                <button onClick={props.getStarted}><img src={SlackIcon} /><span>Get Started</span></button>

            </div>
            <div className='afterTop'>
                <h2>Start using Mixr with Slack in 4 simple steps:</h2>
                <div className='instruction'>
                    <h3>1. Login With Slack</h3>
                    <p>Click "Get Started" then "Continue with Slack" to login to Mixr!</p>
                    <img className='instructionsImg' src={loginP} />
                </div>


                <div className='instruction'>
                    <h3>2. Add Mixr to your Slack Workspace</h3>
                    <p>If you don't have permission, please reach out to your IT team!</p>
                    <img className='instructionsImg' src={addP} />
                </div>
                <div className='instruction'>
                    <h3>3. Select a Mixr plan</h3>
                    <p>All plans come with a free 2 week trial! You can change this at any time. </p>
                    <img className='instructionsImg' src={planP} />
                </div>
                <div className='instruction'>
                    <h3>4. Start a Mixr tournament!</h3>
                    <p>Game tournaments are started from the Mixr Home page on Slack.
                        You can also check out the Slack-wide leaderboards.</p>
                    <img className='instructionsImg' src={slackP} />
                </div>

                <button onClick={props.getStarted}><img src={SlackIcon} onClick={props.getStarted} />Get Started</button>

            </div>
            <div className='learnmore'>
                <h1>Interested in learning more about Mixr?</h1>
                <button onClick={props.home}>Learn more</button>
                <br/>
                <br/>
                <h1>Need Help?</h1>
                <button onClick={()=>{window.location.pathname='help'}}>Go to Help Center</button>
            </div>
        </div>
    )
}
