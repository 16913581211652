import React, { useEffect } from 'react'
import teamwork from '../assets/aboutus/teamwork.png'
import { ReactComponent as Arrow } from '../assets/general/long-arrow-alt-right-solid.svg'
import logo from '../assets/logos/logo.svg';
import oldLogo from '../assets/logos/oldLogo.png'
import Fade from 'react-reveal/Fade';
export default function PageNotFound(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='pageNotFound'>
            <Fade>
                <div className='mission'>

                    <h1>
                        Page not found
                    </h1>
                    <button
                        onClick={() => {
                            window.location.pathname = ''
                        }}>Go to Home Page</button>

                </div>
            </Fade>
        </div>
    )
}
